<template>
  <div class="container-page big">
    <h2 class="title title--h2">Карточка ДС</h2>
    <div class="container-card">
      <form action="/">
        <div class="coll coll-12 m-24">
          <ComponentInput
            label="Наименование Делового совета <span>*</span>"
            v-model="name"
            required
            maxLength="500"
            placeholder="Введите"
            mod="rectangular"
          />
        </div>
        <div class="coll coll-12 m-24">
          <div class="label-field">Страна <span>*</span></div>
          <SelectMultiComponent
            v-model="countryId"
            :options="countryList"
            placeholder="Выберите"
            :classSelect="{ error: errorCountryId }"
            modifier="rectangular"
            required
          />
          <div v-if="errorCountryId" class="invalid-msg">Поле обязательно для заполнения</div>
        </div>
        <div class="coll coll-12 m-24">
          <div class="label-field">Председатель Российской части Делового совета</div>
          <SelectMultiComponent
            v-model="chairman"
            :options="chairmanList"
            placeholder="Выберите"
            modifier="rectangular"
          />
        </div>
        <div class="coll coll-12 m-24">
          <div class="label-field">Председатель иностранной части Делового совета</div>
          <SelectMultiComponent
            v-model="foreignChairman"
            :options="foreignParticipantsList"
            placeholder="Выберите"
            modifier="rectangular"
          />
        </div>
        <div v-if="errorForm" class="coll coll-12 m-24">
          <div class="error-content">Произошла ошибка, пожалуйста, повторите попытку.</div>
        </div>

        <div class="coll coll-6 coll-m-12 m-24">
          <ButtonComponent
            @click.prevent.stop="save"
            mod="gradient-bg"
            class="button-redesign--map-apply button-redesign--commission-form"
            >Сохранить</ButtonComponent
          >
        </div>
        <div class="coll coll-6 coll-m-12 m-24">
          <ButtonComponent @click.prevent.stop="close" class="button-redesign--commission-form"
            >Отменить</ButtonComponent
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';

  import ComponentInput from '@/common/components/ComponentInput';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';
  import Utils from '@/common/utils';

  import API from '../api/business-tips';

  export default {
    name: 'CardBusinessTips',
    components: {
      ButtonComponent,
      ComponentInput,
      SelectMultiComponent,
    },
    data() {
      return {
        isEdit: this.$route.params.type === 'edit',
        id: this.$route.params.id,
        name: '',
        chairman: '',
        foreignChairman: '',
        countryId: '',
        countryList: [],
        errorCountryId: false,
        errorForm: false,
        chairmanList: [],
        foreignParticipantsList: [],
        chairmanName: '',
        foreignChairmanName: '',
      };
    },
    created() {
      Utils.searchNsi('countryListWithData', {})
        .then((response) => {
          this.countryList = response.data.map((i) => ({
            value: i.id,
            label: i.nameFull || i.name,
          }));
        })
        .catch((err) => {
          console.error(err);
        });

      API.getParticipants()
        .then((response) => {
          if (response.data.items) {
            this.chairmanList = response.data.items.map((i) => ({
              value: i.id,
              label: i.name,
            }));
          }
        })
        .catch((err) => {
          console.error(err);
        });

      API.getForeignParticipants()
        .then((response) => {
          if (response.data.items) {
            this.foreignParticipantsList = response.data.items.map((i) => ({
              value: i.id,
              label: i.name,
            }));
          }
        })
        .catch((err) => {
          console.error(err);
        });

      if (this.isEdit) {
        API.getBusinessCardDetail({ id: this.id })
          .then((response) => {
            const { chairman = '', countryId = 1, name = '', foreignChairman = '' } = response.data;
            this.name = name;
            this.chairmanName = chairman;
            this.foreignChairmanName = foreignChairman;
            this.countryId = countryId;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    watch: {
      errorForm(val) {
        if (val) {
          setTimeout(() => {
            this.errorForm = false;
          }, 5000);
        }
      },

      countryId() {
        this.errorCountryId = false;
      },

      chairmanList() {
        if (this.isEdit) {
          const indexChairman = this.chairmanList.findIndex((i) => i.label == this.chairmanName);
          if (indexChairman !== -1) {
            this.chairman = this.chairmanList[indexChairman].value;
          }

          const indexForeignChairman = this.foreignParticipantsList.findIndex(
            (i) => i.label == this.foreignChairmanName,
          );
          if (indexForeignChairman !== -1) {
            this.foreignChairman = this.foreignParticipantsList[indexForeignChairman].value;
          }
        }
      },
    },
    methods: {
      save() {
        this.v$.$touch();
        if (!this.countryId) {
          this.errorCountryId = true;
        }
        if (this.v$.$invalid) return;
        let chairman = '';
        let foreignChairman = '';
        if (this.chairman) {
          chairman = this.chairmanList.filter((i) => +i.value === +this.chairman)[0].label;
        }
        if (this.foreignChairman) {
          foreignChairman = this.foreignParticipantsList.filter((i) => +i.value === +this.foreignChairman)[0].label;
        }
        const formData = {
          name: this.name,
          chairman,
          foreignChairman,
          countryId: this.countryId,
        };
        if (!this.isEdit) {
          API.postCreateBusinessCard(formData)
            .then(() => {
              Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
              this.$router.push({ name: 'business-tips' });
            })
            .catch((err) => {
              console.error('error methods Create Business Tips', err);
              this.errorForm = true;
            });
        } else {
          API.putEditBusinessCard({ id: +this.id, data: formData })
            .then(() => {
              Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
              this.$router.push({ name: 'business-tips' });
            })
            .catch((err) => {
              console.error('error methods Create Business Tips', err);
              this.errorForm = true;
            });
        }
      },
      close() {
        this.$router.push({ name: 'business-tips' });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        name: { required },
        countryId: { required },
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';
  @import '@/modules/crossCuttingTask/styles/coll.scss';

  .container-card {
    margin-bottom: 50px;
    form {
      max-width: 464px;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
      flex-wrap: wrap;
      display: flex;
      padding: 24px 16px 0;
    }

    @media (max-width: $mobile) {
      margin-bottom: 0;
      form {
        padding: 20px 8px 8px;
      }
    }
  }
</style>
